import * as React from 'react';
import Box from '@mui/material/Box';
import CssBaseline from '@mui/material/CssBaseline';
import BottomNavigation from '@mui/material/BottomNavigation';
import BottomNavigationAction from '@mui/material/BottomNavigationAction';
import Paper from '@mui/material/Paper';
import { Link as RouterLink } from 'react-router-dom';
import SignalCellularAltIcon from '@mui/icons-material/SignalCellularAlt';
import AccountBalanceWalletIcon from '@mui/icons-material/AccountBalanceWallet';
import CurrencyExchangeIcon from '@mui/icons-material/CurrencyExchange';

export default function Footer() {
  const [value, setValue] = React.useState(0);
  const ref = React.useRef(null);

  return (
    <Box sx={{ display: {md: 'none'},  pb: 7 }} ref={ref}>
      <CssBaseline />
      <Paper sx={{ position: 'fixed', bottom: 0, left: 0, right: 0 }} elevation={3}>
        <BottomNavigation
          showLabels
          value={value}
          onChange={(event, newValue) => {
            setValue(newValue);
          }}
        >
          <BottomNavigationAction component={RouterLink} to="/" label="Market" icon={<SignalCellularAltIcon />} />
          <BottomNavigationAction component={RouterLink} to="/wallet" label="Wallet" icon={<AccountBalanceWalletIcon />} />
          <BottomNavigationAction component={RouterLink} to="/plans" label="Invest" icon={<CurrencyExchangeIcon />} />
          <BottomNavigationAction component={RouterLink} to="/profile" label="Account" icon={<CurrencyExchangeIcon/>} />
          
          {/* <BottomNavigationAction label="Our Menu" icon={<MenuBookIcon />}/> */}
          
        </BottomNavigation>
      </Paper>
    </Box>
  );
}
