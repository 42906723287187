import React, {useState, useEffect} from 'react'
import Grid from '@mui/material/Grid';
import Card from '@mui/material/Card';
import CardMedia from '@mui/material/CardMedia';
import { CardActions } from '@mui/material';
import Typography from '@mui/material/Typography';
import CardContent from '@mui/material/CardContent';
import { Link as RouterLink } from 'react-router-dom';
import { Link } from '@mui/material';
import Divider from '@mui/material/Divider';
import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';
import OutlinedInput from '@mui/material/OutlinedInput';
import { Button } from '@mui/material';
import { useMutation } from "react-query";
import apiClient from '../../request/http-common';
import CircularProgress from '@mui/material/CircularProgress';
import { useDispatch } from 'react-redux';
import Box from '@mui/material/Box';
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import { useSelector } from 'react-redux';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import { useQuery } from 'react-query';

const CreatePlans = () => {
    const [plans, setplans] = useState(null);
    const { isLoading: isLoadingplans, refetch: getplans } = useQuery(
        "plans",
        async () => {
          return await apiClient.get("/api/plans");
        },
        {
          enabled: true,
          onSuccess: (res) => {
            if (res.data.status === 'success') {
                setplans(res.data.plans);
            }
          },
          onError: (err) => {
            // console.log(err.response?.data || err);
          },
        }
      );

      
    const dispatch = useDispatch();
    
    const initialLogin = {
      comapny_name : ''	,
        mail_port : ''	,
        mail_host : ''	,
        mail_encryption : ''	,
        mail_username: '',
        mail_password : ''	,
        mail_from_address : ''	,
        mail_logo : ''	,
        logo_width : '',
        logo_height: ''
    }
    
    
    const [error, setErros] = useState('');
    const [planForm, setPlanForm] = useState(initialLogin);
    const initialImage = {
      image: ''
  }
    const onChange = (e) =>
    setPlanForm({ ...planForm, [e.target.name]: e.target.value });

    const [productImage, setImage] = useState(initialImage);
    const  onFileChange = (event) => { 
        setImage({...productImage, image: event.target.files[0]});
      }; 

    const { isLoading: isSendingRequest, mutate: postPlan } = useMutation(

        async () => {
          const formData = new FormData();
          formData.append('comapny_name', planForm.comapny_name);
          formData.append('mail_port', planForm.mail_port	);
          formData.append('mail_host', planForm.mail_host	);
          formData.append('mail_encryption', planForm.mail_encryption	);
          formData.append('mail_password', planForm.mail_password	);
          formData.append('mail_from_address', planForm.mail_from_address	);
          formData.append('mail_logo', productImage.image);
          formData.append('logo_width', planForm.logo_width);
          formData.append('logo_height', planForm.logo_height);
          formData.append('mail_username', planForm.mail_username);

          return await apiClient.post(`/api/store-company`, formData, {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          });
        },
        {
          onSuccess: (res) => {
            localStorage.setItem('notification', JSON.stringify({message : `${res.data.message}`, 'type': 'success' }));
            const event = new Event('newMessage');
            window.dispatchEvent(event);
            const event2 = new Event('processed');
            window.dispatchEvent(event2);
          },
          onError: (err) => {   
            let myerror = err.response?.data || err;         
            setErros(myerror.errors)  
            const event2 = new Event('processed');
            window.dispatchEvent(event2);     
          },
        }
      );
      const onSubmit = () =>{
        const event3 = new Event('processing');
        window.dispatchEvent(event3);
        postPlan();
      } 

      useEffect(() => {
        getplans();
      }, []);

  return (
    <div style={{marginTop: '70px'}}>
        <Grid container px={2} mt={4}  mb={4} rowSpacing={1} columnSpacing={{ xs: 1, sm: 1, md: 1 }}>
            <Grid item xs={12} md={12}>
                <Card sx={{marginX: '5px', borderRadius: '9px', height: '100%'}}>
                    <CardContent>
                    <Typography mt={4} sx={{ fontWeight: 'bold', fontSize: 24, textAlign: 'center'  }} gutterBottom variant="h6" component="div">
                        CREATE MAIL COMPANY
                    </Typography>
                    <Typography  sx={{  textAlign: 'center', fontSize: 16  }} variant="subtitle1" gutterBottom>
                        Fill the form below to create new mailing company
                    </Typography>

                    <FormControl fullWidth={true} sx={{ m: 1, minWidth: 50 }}>
                            <InputLabel htmlFor="Company Name">Company Name</InputLabel>
                            {(error !== '' && error.comapny_name) ?
                            <OutlinedInput
                            error
                            onChange={onChange}
                            id="comapny_name"
                            name="comapny_name"
                            type={'text'}
                            label="Company Name"
                            helperText={error.comapny_name}
                            />
                            :
                            <OutlinedInput
                            onChange={onChange}
                            id="comapny_name"
                            name="comapny_name"
                            type={'text'}
                            label="Company Name"
                            />
                            }
                            <Typography color='error'  sx={{  textAlign: 'left', fontSize: 16  }} variant="subtitle1" gutterBottom>
                            {error !== '' && error.comapny_name}
                            </Typography>
                            
                        </FormControl>

                        

                        <FormControl fullWidth={true} sx={{ m: 1, minWidth: 50 }}>
                            <InputLabel htmlFor="mail_host">Mail Host</InputLabel>
                            {(error !== '' && error.mail_host) ?

                            <OutlinedInput
                            onChange={onChange}
                            error
                            id="mail_host"
                            type={'text'}
                            name="mail_host"
                            label="Mail Host"
                            helperText={error.mail_host}
                            />
                            :
                            <OutlinedInput
                            onChange={onChange}
                            id="mail_host"
                            type={'text'}
                            name="mail_host"
                            label="Mail Host"
                            />
                            }
                            <Typography color='error'  sx={{  textAlign: 'left', fontSize: 16  }} variant="subtitle1" gutterBottom>
                            {error !== '' && error.mail_host}
                            </Typography>
                            
                        </FormControl>

                        <FormControl fullWidth={true} sx={{ m: 1, minWidth: 50 }}>
                            <InputLabel htmlFor="mail_port">Mail Port</InputLabel>
                            {(error !== '' && error.mail_port) ?

                            <OutlinedInput
                            onChange={onChange}
                            error
                            id="mail_port"
                            type={'number'}
                            name="mail_port"
                            label="Mail Port"
                            helperText={error.mail_port}
                            />
                            :
                            <OutlinedInput
                            onChange={onChange}
                            id="mail_port"
                            type={'number'}
                            name="mail_port"
                            label="Mail Port"
                            />
                            }
                            <Typography color='error'  sx={{  textAlign: 'left', fontSize: 16  }} variant="subtitle1" gutterBottom>
                            {error !== '' && error.mail_port}
                            </Typography>
                        </FormControl>

                        <FormControl fullWidth={true} sx={{ m: 1, minWidth: 50 }}>
                          <InputLabel htmlFor="mail_username">Mail Username</InputLabel>
                          {(error !== '' && error.mail_username) ?

                          <OutlinedInput
                          onChange={onChange}
                          error
                          id="mail_username"
                          type={'text'}
                          name="mail_username"
                          label="Mail Username"
                          helperText={error.mail_username}
                          />
                          :
                          <OutlinedInput
                          onChange={onChange}
                          id="mail_username"
                          type={'text'}
                          name="mail_username"
                          label="Mail Username"
                          />
                          }
                          <Typography color='error'  sx={{  textAlign: 'left', fontSize: 16  }} variant="subtitle1" gutterBottom>
                          {error !== '' && error.mail_username}
                          </Typography>
                      </FormControl>

                        <FormControl fullWidth={true} sx={{ m: 1, minWidth: 50 }}>
                            <InputLabel htmlFor="mail_password">Mail Password</InputLabel>
                            {(error !== '' && error.mail_password) ?

                            <OutlinedInput
                            onChange={onChange}
                            error
                            id="mail_password"
                            type={'text'}
                            name="mail_password"
                            label="Mail Password"
                            helperText={error.mail_password}
                            />
                            :
                            <OutlinedInput
                            onChange={onChange}
                            id="mail_password"
                            type={'text'}
                            name="mail_password"
                            label="Mail Password"
                            />
                            }
                            <Typography color='error'  sx={{  textAlign: 'left', fontSize: 16  }} variant="subtitle1" gutterBottom>
                            {error !== '' && error.mail_password}
                            </Typography>
                            
                        </FormControl>

                        <FormControl fullWidth={true} sx={{ m: 1, minWidth: 50 }}>
                            <InputLabel htmlFor="mail_encryption">Mail Encryption</InputLabel>
                            {(error !== '' && error.mail_encryption) ?

                            <OutlinedInput
                            onChange={onChange}
                            error
                            id="mail_encryption"
                            type={'text'}
                            name="mail_encryption"
                            label="Mail Encryption"
                            helperText={error.mail_encryption}
                            />
                            :
                            <OutlinedInput
                            onChange={onChange}
                            id="mail_encryption"
                            type={'text'}
                            name="mail_encryption"
                            label="Mail Encryption"
                            />
                            }
                            <Typography color='error'  sx={{  textAlign: 'left', fontSize: 16  }} variant="subtitle1" gutterBottom>
                            {error !== '' && error.mail_encryption}
                            </Typography>
                            
                        </FormControl>

                        <FormControl fullWidth={true} sx={{ m: 1, minWidth: 50 }}>
                            <InputLabel htmlFor="mail_from_address">Mail From Address</InputLabel>
                            {(error !== '' && error.mail_from_address) ?

                            <OutlinedInput
                            onChange={onChange}
                            error
                            id="mail_from_address"
                            type={'text'}
                            name="mail_from_address"
                            label="Mail From Address"
                            helperText={error.mail_from_address}
                            />
                            :
                            <OutlinedInput
                            onChange={onChange}
                            id="mail_from_address"
                            type={'text'}
                            name="mail_from_address"
                            label="Mail From Address"
                            />
                            }
                            <Typography color='error'  sx={{  textAlign: 'left', fontSize: 16  }} variant="subtitle1" gutterBottom>
                            {error !== '' && error.mail_from_address}
                            </Typography>
                            
                        </FormControl>

                        <FormControl fullWidth={true} sx={{ m: 1, minWidth: 50 }}>
                            <InputLabel htmlFor="logo_width">Logo Width</InputLabel>
                            {(error !== '' && error.logo_width) ? 
                            <OutlinedInput
                            error
                            onChange={onChange}
                            minRows={2} multiline
                            id="logo_width"
                            name="logo_width"
                            type={'text'}
                            label="Logo Width"
                            helperText={error.logo_width}
                            />
                            :
                            <OutlinedInput
                            onChange={onChange}
                            minRows={2} multiline
                            id="logo_width"
                            name="logo_width"
                            type={'text'}
                            label="Logo Width"
                            />
                            }
                            <Typography color='error'  sx={{  textAlign: 'left', fontSize: 16  }} variant="subtitle1" gutterBottom>
                            {error !== '' && error.logo_width}
                            </Typography>
                        </FormControl>


                        <FormControl fullWidth={true} sx={{ m: 1, minWidth: 50 }}>
                            <InputLabel htmlFor="logo_height">Logo Height</InputLabel>
                            {(error !== '' && error.logo_height) ? 
                            <OutlinedInput
                            error
                            onChange={onChange}
                            minRows={2} multiline
                            id="logo_height"
                            name="logo_height"
                            type={'text'}
                            label="Logo Height"
                            helperText={error.logo_height}
                            />
                            :
                            <OutlinedInput
                            onChange={onChange}
                            minRows={2} multiline
                            id="logo_height"
                            name="logo_height"
                            type={'text'}
                            label="Logo Height"
                            />
                            }
                            <Typography color='error'  sx={{  textAlign: 'left', fontSize: 16  }} variant="subtitle1" gutterBottom>
                            {error !== '' && error.logo_height}
                            </Typography>
                        </FormControl>

                        <FormControl fullWidth={false} sx={{ m: 1, minWidth: 50 }}>
                        <Typography  sx={{  textAlign: 'center', fontSize: 16, fontWeight: 'bold'  }} variant="subtitle1" gutterBottom>
                            Upload Company Logo
                        </Typography>
                        <Box>
                        {productImage.image !== '' &&
                            <img style={{width: "180px", height: "auto"}} src={URL.createObjectURL(productImage.image)} alt="product"/>
                        }
                        </Box>
                            {(error !== '' && error.mail_logo) ?

                            <OutlinedInput
                            onChange={onFileChange}
                            error
                            accept="image/*"
                            id="mail_logo"
                            type='file'
                            name="mail_logo"
                            helperText={error.mail_logo}
                            />
                            :
                            <OutlinedInput
                            accept="image/*"
                            onChange={onFileChange}
                            id="mail_logo"
                            type='file'
                            name="mail_logo"
                            />
                            }
                            <Typography color='error'  sx={{  textAlign: 'left', fontSize: 16  }} variant="subtitle1" gutterBottom>
                            {error !== '' && error.mail_logo}
                            </Typography>
                        </FormControl>
                        
                    </CardContent>   

                    <CardActions>
                        <Button onClick={onSubmit} fullWidth={true} size="large" color="primary" variant="contained"> {isSendingRequest? 'Creating...' : 'Create Mailer'}</Button>
                    </CardActions>
                </Card>
            </Grid>
        </Grid>
    </div>
  )
}

export default CreatePlans
