import * as React from 'react';
import { useEffect, useState } from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { useQuery } from 'react-query';
import Avatar from '@mui/material/Avatar';
import apiClient from '../../request/http-common';
import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';
import { Button } from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import { Link as RouterLink } from 'react-router-dom';

export default function ListPlans() {
    const [plans, setplans] = useState(null);
    const { isLoading: isLoadingplans, refetch: getplans } = useQuery(
        "show-company",
        async () => {
          return await apiClient.get("/api/show-company");
        },
        {
          enabled: false,
          onSuccess: (res) => {
            if (res.data.status === 'success') {
                setplans(res.data.companies);
                const event2 = new Event('processed');
                window.dispatchEvent(event2);
            }
          },
          onError: (err) => {
            // console.log(err.response?.data || err);
          },
        }
      );
    
      useEffect(() => {
        getplans() 
        if (isLoadingplans) {
            const event3 = new Event('processing');
            window.dispatchEvent(event3);
        }
      }, []);

  const getDate = (date) => new Date(date).toDateString();
  return (
    <div style={{marginTop: '75px'}}>
    <Container>
        <Typography  mt={4} mb={2} sx={{ fontWeight: 'bold', fontSize: 20, textAlign: 'center', textTransform: 'uppercase' }} gutterBottom variant="h6" component="div">
        
        {isLoadingplans? 'Loading Companies...' : 'List of Companies'}
      </Typography>
    <TableContainer component={Paper}>
        
      {plans !== null &&
      <Table sx={{ minWidth: '100%' }} size="small" aria-label="a dense table">
      <TableHead>
        <TableRow>
        
        <TableCell>Cmpany Name</TableCell>
          <TableCell align="right">Mail Host</TableCell>
          <TableCell align="right">Mail Port</TableCell>
          <TableCell align="right">Mail Username</TableCell>
          <TableCell align="right">Mail Password</TableCell>
          <TableCell align="right">Mail Encryption</TableCell>
          <TableCell align="right">Mail From Address</TableCell>
          <TableCell align="right">Mail Logo</TableCell>
          <TableCell align="right">Logo Width</TableCell>
          <TableCell align="right">Logo Height</TableCell>
          <TableCell align="right">View</TableCell>
          <TableCell align="right">Date</TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {plans.map((plan) => (
          <TableRow
            key={plan.id}
            sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
          >
            <TableCell component="th" scope="row">
            {plan.comapny_name}
            </TableCell>
            <TableCell align="right">{plan.mail_host}</TableCell>
            <TableCell align="right">{plan.mail_port}</TableCell>
            <TableCell align="right">{plan.mail_username}</TableCell>
            <TableCell align="right">{plan.mail_password}%</TableCell>
            <TableCell align="right">{plan.mail_encryption}</TableCell>
            <TableCell align="right">{plan.mail_from_address}</TableCell>
            <TableCell align="right">{plan.mail_logo}</TableCell>
            <TableCell align="right">{plan.logo_width}</TableCell>
            <TableCell align="right">{plan.logo_height}</TableCell>
            <TableCell align="right"><Button component={RouterLink} to={`/edit-plan/${plan.id}`} startIcon={<EditIcon />}>Edit Cmopany</Button></TableCell>
            <TableCell align="right">{getDate(plan.created_at)}</TableCell>
          </TableRow>
        ))}
      </TableBody>
    </Table>
      }
      
    </TableContainer>
    </Container>
    </div>
  );
}