import React, {useState, useEffect} from 'react'
import Grid from '@mui/material/Grid';
import Card from '@mui/material/Card';
import { CardActions } from '@mui/material';
import Typography from '@mui/material/Typography';
import CardContent from '@mui/material/CardContent';
import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';
import OutlinedInput from '@mui/material/OutlinedInput';
import { Button } from '@mui/material';
import { useMutation } from "react-query";
import apiClient from '../../../request/http-common';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';

import { EditorState, convertToRaw } from 'draft-js';
import { Editor } from 'react-draft-wysiwyg';
import draftToHtml from 'draftjs-to-html';
import htmlToDraft from 'html-to-draftjs';
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import { BorderStyle } from '@mui/icons-material';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import { useQuery } from 'react-query';

const AdminMail = () => {
          
    const initialCategory = {
        title: '',
        content: '',
        mail_content: '',
        receiver: '',
        all: ''
    }
    
    const [error, setErros] = useState('');
    const [mailForm, setmailForm] = useState(initialCategory);
    const [value, setValue] = useState('');

    
    const [plans, setplans] = useState(null);
    const { isLoading: isLoadingplans, refetch: getplans } = useQuery(
        "show-company",
        async () => {
          return await apiClient.get("/api/show-company");
        },
        {
          enabled: false,
          onSuccess: (res) => {
            if (res.data.status === 'success') {
                setplans(res.data.companies);
                const event2 = new Event('processed');
                window.dispatchEvent(event2);
            }
          },
          onError: (err) => {
            // console.log(err.response?.data || err);
          },
        }
      );

    const [editorState, setEditorState] = useState(EditorState.createEmpty());
   
    const onChange = (e) =>
    setmailForm({ ...mailForm, [e.target.name]: e.target.value });


    const { isLoading: isSendingRequest, mutate: postCategory } = useMutation(

        async () => {
          return await apiClient.post(`/api/send-mail`, {
            title: mailForm.title,
            content: mailForm.content,
            mail_content: value,
            receiver: mailForm.receiver,
            company_id: mailForm.all
          });
        },
        {
          onSuccess: (res) => {
            localStorage.setItem('notification', JSON.stringify({message : `${res.data.message}`, 'type': 'success' }));
            const event = new Event('newMessage');
            window.dispatchEvent(event);
            const event2 = new Event('processed');
            window.dispatchEvent(event2);
          },
          onError: (err) => {   
            let myerror = err.response?.data || err;         
            setErros(myerror.errors)  
            const event2 = new Event('processed');
            window.dispatchEvent(event2);     
          },
        }
      );
      const onSubmit = () =>{
        const event3 = new Event('processing');
        window.dispatchEvent(event3);
        postCategory();
      } 

      useEffect(() => {
        getplans();
      }, []);

  return (
    <div style={{marginTop: '70px'}}>
        <Grid container px={2} mt={4}  mb={4} rowSpacing={1} columnSpacing={{ xs: 1, sm: 1, md: 1 }}>
            <Grid item xs={12} md={12}>
                <Card sx={{marginX: '5px', borderRadius: '9px', height: '100%'}}>
                    <CardContent>
                    <Typography mt={4} sx={{ fontWeight: 'bold', fontSize: 24, textAlign: 'center'  }} gutterBottom variant="h6" component="div">
                       SEND EMAILS
                    </Typography>
                    <Typography  sx={{  textAlign: 'center', fontSize: 16  }} variant="subtitle1" gutterBottom>
                        Fill the form below to send mails
                    </Typography>

                    <FormControl fullWidth={true} sx={{ m: 1, minWidth: 50 }}>
                            <InputLabel htmlFor="title">Mail Title</InputLabel>
                            {(error !== '' && error.title) ?
                            <OutlinedInput
                            error
                            onChange={onChange}
                            id="title"
                            name="title"
                            type={'text'}
                            label="Mail Title"
                            helperText={error.title}
                            />
                            :
                            <OutlinedInput
                            onChange={onChange}
                            id="title"
                            name="title"
                            type={'text'}
                            label="Mail Title"
                            />
                            }
                            <Typography color='error'  sx={{  textAlign: 'left', fontSize: 16  }} variant="subtitle1" gutterBottom>
                            {error !== '' && error.title}
                            </Typography>
                        </FormControl>


                        <FormControl fullWidth={true} sx={{ m: 1, minWidth: 50 }}>
                            <InputLabel htmlFor="all">Select Company</InputLabel>
                            {(error !== '' && error.all) ?
                            <Select
                            error
                            onChange={onChange}
                            labelId="all"
                            id="all"
                            name="all"
                            label={`Select Company`}
                            helperText={error.all}
                            >
                            <MenuItem selected value=''>
                                Select Company
                            </MenuItem>  
                            {(plans != null && plans.length > 0) &&
                            plans.map(plan => (
                                <MenuItem key={plan.id} value={plan.id}>{plan.comapny_name} </MenuItem>
                            ))

                            }            
                            
                        </Select>
                            :
                            <Select
                                onChange={onChange}
                                labelId="all"
                                name="all"
                                id="all"
                                label={`Select Company`}
                                >
                                <MenuItem selected value=''>
                                    Select Company
                                </MenuItem>              
                                {(plans != null && plans.length > 0) &&
                            plans.map(plan => (
                                <MenuItem key={plan.id} value={plan.id}>{plan.comapny_name} </MenuItem>
                            ))

                            } 
                            </Select>
                            }
                            <Typography color='error'  sx={{  textAlign: 'left', fontSize: 16  }} variant="subtitle1" gutterBottom>
                            {error !== '' && error.all}
                            </Typography>
                        </FormControl>


                        <FormControl  fullWidth={true} sx={{ m: 1, minWidth: 50 }}>
                            <InputLabel htmlFor="address">Short Message</InputLabel>
                            {(error !== '' && error.content) ?
                            <OutlinedInput
                            error
                            helperText={error.content}
                            onChange={onChange}
                            minRows={4} multiline
                            id="content"
                            name="content"
                            type={'text'}
                            label="Short Message"
                            />
                            :
                            <OutlinedInput
                            onChange={onChange}
                            minRows={4} multiline
                            id="content"
                            name="content"
                            type={'text'}
                            label="Short Message"
                            />
                            }
                            <Typography color='error'  sx={{  textAlign: 'left', fontSize: 16  }} variant="subtitle1" gutterBottom>
                            {error !== '' && error.content}
                            </Typography>
                        </FormControl>


                        <FormControl  fullWidth={true} sx={{ m: 1, minWidth: 50 }}>
                            <InputLabel htmlFor="address">Mail Recceivers</InputLabel>
                            {(error !== '' && error.receiver) ?
                            <OutlinedInput
                            error
                            helperText={error.receiver}
                            onChange={onChange}
                            minRows={4} multiline
                            id="receiver"
                            name="receiver"
                            type={'text'}
                            label="Mail Recceivers"
                            />
                            :
                            <OutlinedInput
                            onChange={onChange}
                            minRows={4} multiline
                            id="receiver"
                            name="receiver"
                            type={'text'}
                            label="Mail Recceivers"
                            />
                            }
                            <Typography color='error'  sx={{  textAlign: 'left', fontSize: 16  }} variant="subtitle1" gutterBottom>
                            {error !== '' && error.receiver}
                            </Typography>
                        </FormControl>

                        <ReactQuill theme="snow" placeholder="Write mail content here..." value={value} onChange={setValue} />

                        {/* <FormControl  fullWidth={true} sx={{ m: 1, minWidth: 50 }}>
                            <InputLabel htmlFor="address">Mail Content</InputLabel>
                            {(error !== '' && error.mail_content) ?
                            <OutlinedInput
                            error
                            helperText={error.mail_content}
                            onChange={onChange}
                            minRows={4} multiline
                            id="mail_content"
                            name="mail_content"
                            type={'text'}
                            label="Mail Content"
                            />
                            :
                            <OutlinedInput
                            onChange={onChange}
                            minRows={4} multiline
                            id="mail_content"
                            name="mail_content"
                            type={'text'}
                            label="Mail Content"
                            />
                            }
                            <Typography color='error'  sx={{  textAlign: 'left', fontSize: 16  }} variant="subtitle1" gutterBottom>
                            {error !== '' && error.mail_content}
                            </Typography>
                        </FormControl> */}
                        
                    </CardContent>   

                    <CardActions>
                        <Button onClick={onSubmit} fullWidth={true} size="large" color="primary" variant="contained"> {isSendingRequest? 'sending...' : 'Send Email'}</Button>
                    </CardActions>
                </Card>
            </Grid>
        </Grid>
    </div>
  )
}

export default AdminMail
